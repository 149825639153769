//sticky atc
class StickyATC extends HTMLElement {
  constructor() {
    super();
    this.scrollThreshold = 100; // Set scroll distance threshold
    this.atcStatic = document.querySelector('.js-static-atc');
    this.stickyObserver = document.querySelector('.atc-observer');
  }

  connectedCallback() {
      const handleIntersection = (entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              this.atcStatic.classList.remove('sticky-atc');
              if(window.innerWidth < 1024) {
              this.stickyObserver.style.height = "0px";
              }
            } else {
              this.atcStatic.classList.add('sticky-atc');
              if(window.innerWidth < 1024) {
                  this.stickyObserver.style.height = "132px";
              }
            }
          });
        }
        const options = {
          root: null, // using the viewport as the root
          rootMargin: "10px",
          threshold: 1,
        };

        const observer = new IntersectionObserver(handleIntersection, options);
        observer.observe(this.stickyObserver);
  }

}

customElements.define('sticky-atc', StickyATC);
